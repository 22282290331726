//======================= MENU =======================

var burger = this.document.querySelector(".burger");
var menu = this.document.querySelector(".page-header-nav");
var buttonMenu = this.document.querySelectorAll(".burger-nav a");

var burgerOpen = function () {
  burger.classList.toggle("burger-open");
  menu.classList.toggle("page-header-nav-open");
};

burger.addEventListener("click", burgerOpen);

buttonMenu.forEach((button) => {
  button.addEventListener("click", burgerOpen);
});

//======================= DARK/LIGHT MODE =======================

var root = this.document.querySelector(":root");
var buttonDarkLight = this.document.querySelector(".button-dark-light");
const currentTheme = localStorage.getItem("theme");
var isDark = Boolean(false);

if (currentTheme) {
  document.documentElement.setAttribute("data-theme", currentTheme);

  if (currentTheme === "dark") {
    isDark = Boolean(true);
    buttonDarkLight.classList.add("isDark");
  }
}

function toggleDarkLight() {
  if (isDark === Boolean(true)) {
    // root.style.setProperty("--dark-color", "#050505");
    // root.style.setProperty("--light-color", "#fff");
    buttonDarkLight.classList.remove("isDark");
    isDark = Boolean(false);
    document.documentElement.setAttribute("data-theme", "light");
    localStorage.setItem("theme", "light");
  } else {
    // root.style.setProperty("--dark-color", "#fff");
    // root.style.setProperty("--light-color", "#050505");
    buttonDarkLight.classList.add("isDark");
    isDark = Boolean(true);
    document.documentElement.setAttribute("data-theme", "dark");
    localStorage.setItem("theme", "dark");
  }
}

buttonDarkLight.addEventListener("click", toggleDarkLight);

//======================= SPARK =======================
// https://codepen.io/lucasdellabella/pen/RwMyzRd

let styleSheet = null;

const SPARK_ELEMENT_WIDTH = 30;
const DISTANCE = 70;

const RANDOMNESS_ON = true;

/**
 * A burst is a grouping of many sparks
 * A spark is some individual dom element generally used with other sparks to create interesting UI effects.
 **/

/**
 * Util for creating sequences of css transform steps cleanly
 */
function createTransformSteps() {
  if (Array.from(arguments).length === 0) {
    throw Exception("arguments to createTransformSteps should never be empty!");
  }

  const inputSteps = Array.from(arguments);
  const outputSteps = [inputSteps.shift()];
  inputSteps.forEach((step, i) => {
    outputSteps.push(`${outputSteps[i]} ${step}`);
  });

  return outputSteps;
}

/**
 * Creates a new keyframe rule available in css context with a specific spark rotation
 */
const dynamicAnimation = (name, rotation) => {
  if (!styleSheet) {
    styleSheet = document.createElement("style");
    styleSheet.type = "text/css";
    document.head.appendChild(styleSheet);
  }

  /**
  Explaining the transform rules
  1. prepares spark
  2. shoots the spark out
  3. keeps the spark in place while scaling it down
*/

  const randomDist = RANDOMNESS_ON
    ? Math.floor((Math.random() - 0.5) * DISTANCE * 0.7)
    : 0;

  const [s1, s2, s3] = createTransformSteps(
    `translate(-50%, -50%) rotate(${rotation}deg) translate(10px, 0px)`,
    `translate(${DISTANCE + randomDist}px, 0px) scale(0.5, 0.5)`,
    `translate(${SPARK_ELEMENT_WIDTH / 2}px, 0) scale(0, 0)`
  );

  // todo some axis would be good here.
  styleSheet.sheet.insertRule(
    `@keyframes ${name} {
     0% {
       transform: ${s1};
     }
     70% {
       transform: ${s2};
     }
     100% {
       transform: ${s3};
     }
  }`,
    styleSheet.length
  );
};

document.querySelector("body").addEventListener("click", (e) => {
  const center = { x: e.pageX, y: e.pageY };
  makeBurst(center);
});

const makeBurst = (center) => {
  for (let i = 0; i < 8; i++) {
    const randomSpace = RANDOMNESS_ON
      ? Math.floor(-17 + Math.random() * 34)
      : 0;
    makeSpark(center, 45 * i + randomSpace);
  }
};

/**
 * Creates a spark
 */
const makeSpark = (center, rotation) => {
  const div = document.createElement("div");
  const aniName = `disappear_${rotation}`;
  dynamicAnimation(aniName, rotation);

  div.classList.add("spark");
  div.style.left = `${center.x}px`;
  div.style.top = `${center.y}px`;
  div.style.animation = `${aniName} 500ms ease-out both`;

  document.body.append(div);
};
